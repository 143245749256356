import Card from '@comparacar/components/src/generic/CardMobile'
import MainSearch from '@comparacar/components/src/generic/MainSearch'
import Administradores from '@comparacar/components/src/icons/brands/Administradores'
import QuatroRodas from '@comparacar/components/src/icons/brands/QuatroRodas'
import Startups from '@comparacar/components/src/icons/brands/Startups'
import Terra from '@comparacar/components/src/icons/brands/Terra'
import Valor from '@comparacar/components/src/icons/brands/Valor'
import InfoSection from '@comparacar/components/src/main/InfoSection'
import ResponsiveYoutubeEmbed from '@comparacar/components/src/main/ResponsiveYoutubeEmbed'
import LinksSection from '@comparacar/components/src/molecules/LinksSection'
import commonTitles from '@comparacar/lib/src/common-titles'
import { normalizeSubscription } from '@comparacar/lib/src/utils/normalizeToResultCard'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

const Main: React.FC<{ searchOption?: engine; videoList: Video[]; links: Link[]; highlights: Subscription[] }> = ({
  searchOption = 'buy',
  videoList,
  highlights,
  links
}) => {
  return (
    <>
      <Stack gap={6} my={4}>
        <div>
          <Box maxWidth="412" margin="auto" mb={7}>
            <Typography fontWeight="600" align="center" variant="h1" gutterBottom>
              {commonTitles.mainTitle}
            </Typography>
            <Typography fontWeight="600" color="textSecondary" align="center" variant="h2">
              {commonTitles.mainSubtitle}
            </Typography>
          </Box>
          <MainSearch engine={searchOption} />
        </div>
        <div>
          <InfoSection />
        </div>
        <div>
          <Typography variant="h6" mb={3}>
            Somos destaque em:
          </Typography>
          <Stack spacing={2} direction="row" flexWrap="wrap" useFlexGap justifyContent="space-around">
            <QuatroRodas width="25%" />
            <Valor width="25%" />
            <Startups width="25%" />
            <Administradores width="40%" />
            <Terra width="25%" />
          </Stack>
        </div>
        {Boolean(highlights.length) && (
          <div>
            <Typography variant="h6" color="textPrimary" fontWeight={500} mb={3}>
              Ofertas em destaque
            </Typography>
            <Box
              sx={{
                mt: 2,
                '& > *': {
                  mb: 2
                }
              }}
            >
              {highlights.map(item => (
                <Card data={normalizeSubscription(item)} key={item.id} hideOfferButton />
              ))}
            </Box>
          </div>
        )}
        <div>
          <Typography variant="h6" color="textPrimary" fontWeight={500} mb={3}>
            Conteúdo destaque do mês
          </Typography>
          <ResponsiveYoutubeEmbed url={videoList[0].url} />
        </div>
        <LinksSection urlPrefix="/assinar/carro/" links={links} />
      </Stack>
    </>
  )
}

export default Main
